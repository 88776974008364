





























import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class AppSidebar extends Vue {
  expandedLink: any = {};

  userSettingsPageLink = {
    name: 'Settings',
    to: 'Settings',
  }

  links = [
    {
      name: 'Profile',
      to: 'UserProfile',
    },
    {
      name: 'Issue',
      to: 'IssueStatus',
    },
    // {
    //   name: 'Activity',
    //   to: 'UserActivity',
    // },
    {
      name: 'Schedule',
      to: 'UserSchedule',
    },
  ];

  handleSidebarItemClick(link) {
    if (this.expandedLink && link.to === this.expandedLink.to) {
      this.$router.push({
        name: link.to,
      });
      return;
    }
    if (link.children?.length) {
      this.expandedLink = link;
      return;
    }
    this.$router.push({
      name: link.to,
    });
  }

  isActiveLink(link) {
    return link.to === this.$route.name
      || this.$route.meta.parent === link.name;
  }
}
